export default () => (
    {
        navIsOpen: false,
        isMobile: false,
        width: 0,

        isDarkMode: false,

        init(){
            // Set screen width on load
            this.width = window.innerWidth;
            this.setIsMobile();

            // Set theme on load
            this.setTheme();
        },

        setIsMobile(){
            this.isMobile = this.width < 1024;
        },

        setTheme() {
            // Must add .dark class to the html element
            const html = document.querySelector("html");
            var prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

            // Check and apply the user's stored preference if available.
            // Otherwise use the system preference.
            if (!("theme" in localStorage)) {
                if (prefersDark) {
                    this.isDarkMode = true;
                    html.classList.add("dark");
                }
                else {
                    this.isDarkMode = false;
                    html.classList.remove("dark");
                }
            } else {
                if (localStorage.theme === "dark") {
                    this.isDarkMode = true;
                    html.classList.add("dark");
                }
                else {
                    this.isDarkMode = false;
                    html.classList.remove("dark");
                }
            }
            document.cookie = `rbia_theme_preference=${this.isDarkMode ? 'dark' : 'light'}; path=/; max-age=9999999; SameSite=Lax`;
        },

        toggleTheme() {
            this.isDarkMode = ! this.isDarkMode;
            const html = document.querySelector("html");

            if (this.isDarkMode) {
                html.classList.add("dark");
            }
            else {
                html.classList.remove("dark");
            }

            // Add the theme preference to the user's local storage
            localStorage.theme = this.isDarkMode ? "dark" : "light";
        },
    }
);
